import {onDomReady} from "../../components/dynamic/observer";
import {EffectFade, Navigation, Swiper} from 'swiper';
import '../../../node_modules/swiper/swiper.scss';

function init() {
        const leftArrow = document.querySelector('[data-stage-slider-left]');
        const rightArrow = document.querySelector('[data-stage-slider-right]');

        const slider = new Swiper(document.querySelector('[data-stage-slider]'), {
            modules: [Navigation, EffectFade],
            slidesPerView: 1,
            effect: 'fade',
            navigation: {
                prevEl: leftArrow,
                nextEl: rightArrow,
            },
        });
}

onDomReady(init);