import {onDomReady} from "../../components/dynamic/observer";
import {EffectFade, Navigation, Swiper} from 'swiper';
import '../../../node_modules/swiper/swiper.scss';

function init() {
    const leftArrow = document.querySelector('[data-partner-slider-left]');
    const rightArrow = document.querySelector('[data-partner-slider-right]');

    const slider = new Swiper(document.querySelector('[data-partner-slider]'), {
        modules: [Navigation, EffectFade],
        navigation: {
            prevEl: leftArrow,
            nextEl: rightArrow,
        },
        breakpoints: {
            320: {
                slidesPerView: 'auto',
            },
            768: {
                slidesPerView: 'auto',
            },
            1600: {
                slidesPerView: 3,
            },
        },
    });
}

onDomReady(init);