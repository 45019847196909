import {onDomReady} from "../../components/dynamic/observer";
import {EffectFade, Navigation, Swiper} from 'swiper';
import '../../../node_modules/swiper/swiper.scss';

function init() {
    const leftArrow = document.querySelector('[data-projects-slider-left]');
    const rightArrow = document.querySelector('[data-projects-slider-right]');

    const slider = new Swiper(document.querySelector('[data-projects-slider]'), {
        modules: [Navigation, EffectFade],
        // slidesPerView: 1.5,
        slidesPerView: 'auto',
        navigation: {
            prevEl: leftArrow,
            nextEl: rightArrow,
        },
    });
}

onDomReady(init);