import {onDomChanges, onDomReady} from "../../components/dynamic/observer";
import {EffectFade, Navigation, Swiper, Thumbs} from 'swiper';
import '../../../node_modules/swiper/swiper.scss';

function init() {
    const containers = document.querySelectorAll('[data-model-slider-container]');
    containers.forEach((container)=>{
        if(container){
            const leftArrow = container.querySelector('[data-model-slider-left]');
            const rightArrow = container.querySelector('[data-model-slider-right]');

            const indexMiniSlider = new Swiper(container.querySelector('[data-model-mini-slider]'),{
                spaceBetween: 10,
                slidesPerView: 4,
                watchSlidesProgress: true,
            });

            const indexImageSlider = new Swiper(container.querySelector('[data-model-slider]'), {
                modules: [Navigation, Thumbs],
                spaceBetween: 10,
                thumbs: {
                    swiper: indexMiniSlider,
                },
                navigation: {
                    prevEl: leftArrow,
                    nextEl: rightArrow,
                },
            });
        }
    })
};

onDomChanges(init);